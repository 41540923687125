import React, { useState, useEffect } from 'react';
import { Menu, Icon } from 'antd';
import { requestGet, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, getTranslation } from '../../utils/Session';
import { getSelectedLanguage } from '../../utils/Utils';
import { CONSTANTS_REQ } from '../../utils/Constants';
import 'animate.css';
import { LanguageSelector } from '../../components/Language';
import './MenuBar.css';

const MenuBar = () => {
    const [loginHref, setLoginHref] = useState(undefined);
    const [aboutUsHref, setAboutUsHref] = useState(undefined);
    const [primeResponsive, setPrimeResponsive] = useState('prime-menu');
    const selLang = getSelectedLanguage();

    useEffect(() => {
        getLoginHref();
        getAboutUsHref();
    }, []);

    async function getLoginHref() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.EDIT, {
            group: 'prime',
            key: 'prime.login',
            projectId: 0,
        });
        if (resp.status === RESPONSE.OK) {
            if (resp.data.settingValue != null) {
                setLoginHref(resp.data.settingValue);
            }
        }
    }

    async function getAboutUsHref() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.EDIT, {
            group: 'prime',
            key: 'prime.aboutus',
            projectId: 0,
        });
        if (resp.status === RESPONSE.OK) {
            if (resp.data.settingValue != null) {
                setAboutUsHref(resp.data.settingValue);
            }
        }
    }

    const setResponsive = () => {
        console.log(primeResponsive);
        if (primeResponsive === 'prime-menu') {
            setPrimeResponsive('prime-menu responsive');
        } else {
            setPrimeResponsive('prime-menu');
        }
    };

    return (
        // <Menu mode="horizontal" align="right" className="prime-menu">
        //     <Menu.Item key="aboutUs">
        //         <a href={aboutUsHref} target="blank" className="prime-font">
        //             {geti18nText('prime.aboutUs')}
        //         </a>
        //     </Menu.Item>
        //     <Menu.Item key="login">
        //         <a href={loginHref} target="blank" className="prime-font">
        //             {geti18nText('prime.login')}
        //         </a>
        //     </Menu.Item>
        //     <Menu.Item key="language">
        //         <div className="select-dark">
        //             <LanguageSelector className="prime-font" />
        //         </div>
        //     </Menu.Item>
        // </Menu>
        <div className={'prime-top-bar'} style={{ marginTop: '0px' }}>
            <a href="javascript:void(0);" class="prime-icon" onClick={setResponsive}>
                <Icon type="menu" />
            </a>
            <div className={primeResponsive}>
                <a href={aboutUsHref} className="prime-font prime-menu-item">
                    {getTranslation('prime.aboutUs', selLang)}
                </a>
                <a href={loginHref} className="prime-font prime-menu-item">
                    {getTranslation('prime.login', selLang)}
                </a>
                <div className="select-dark prime-menu-item">
                    <LanguageSelector className="prime-font" />
                </div>
            </div>
        </div>
    );
};

export default MenuBar;
