import React, { useState, useEffect } from 'react';
import { geti18nText, getTranslation } from '../../utils/Session';
import { getSelectedLanguage } from '../../utils/Utils';
import { Row, Col, Form, Select, Button, Icon } from 'antd';
import { getTranslated } from '../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { applicationLogo } from '../../components/adminLayout/AdminMainMenu';
import MapWidget from './MapWidget';
import MenuBar from './MenuBar';
import './Prime.css';
import { Search } from '@nybble/ny-tourist-components';
import { requestGet, RESPONSE } from '../../utils/RequestResolver';
import { useHistory } from 'react-router-dom';

const PrimeIndex = () => {
    const history = useHistory();
    const [logo, setLogo] = useState(undefined);
    const selLang = getSelectedLanguage();

    useEffect(() => {
        setLogo(applicationLogo(logo));
    }, []);

    return (
        <Row>
            <Row>
                <Col span={24}>
                    <MenuBar />
                </Col>
            </Row>
            <Row>
                <Col span={24} align="middle">
                    <img src={logo} height={120} style={{ marginTop: '-15px', display: 'block' }} />
                    <h1 class="prime-title">{getTranslation('app.title', selLang)}</h1>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <MapWidget />
                </Col>
            </Row>
            <Row>
                <Col span={24} align="middle">
                    <Search
                        className="prime-title"
                        getSelectedClientLanguage={getSelectedLanguage}
                        getTranslated={getTranslated}
                        CONSTANTS_REQ={CONSTANTS_REQ}
                        getTranslated={getTranslated}
                        requestGet={requestGet}
                        RESPONSE={RESPONSE}
                        geti18nText={geti18nText}
                        Form={Form}
                        Select={Select}
                        Option={Option}
                        Button={Button}
                        history={history}
                        projectType={'prime'}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default PrimeIndex;
