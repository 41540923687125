import React from "react";
import { Session } from "../utils/Session";
import { getSelectedLanguage, saveDefaults, getSelectedClientLanguage } from "../utils/Utils";
import Flag from "react-world-flags";
import { Select } from "antd";
import config from "../config.json";
const { Option } = Select;

export const LanguageSelector = (props) => {
    const i18n = Session.getAppValue("I18N");
    const projInfo = Session.getProjectInfo();

    function changeLanguage(value) {
        const selectedLang = { lang: value.toLowerCase() };
        saveDefaults(selectedLang);
        window.location.reload();
    }

    function changeClientLanguage(value) {
        const selectedClientLang = { clientLang: value.toLowerCase() };
        saveDefaults(selectedClientLang);
        window.location.reload();
    }

    if (!props.client && i18n) {
        return (
            <Select disabled={props.disabled} defaultValue={getSelectedLanguage()} style={{ width: 70 }} onSelect={changeLanguage}>
                {i18n.language.available.map((value, index) => {
                    return (
                        <Option key={index} value={value}>
                            {value.toUpperCase()}
                        </Option>
                    );
                })}
            </Select>
        );
    } else {
        return (
            <Select disabled={props.disabled} defaultValue={!props.selectedLanguage ? props.selectedLanguage : getSelectedClientLanguage()} style={{ width: 70 }} onSelect={changeClientLanguage}>
                {projInfo != null &&
                    projInfo.languages != null &&
                    projInfo.languages.map((value, index) => {
                        const lang = Object.keys(value)[0];
                        return (
                            <Option key={lang} value={lang}>
                                {config.langFlag ? <Flag code={Object.values(value)[0]} height="10" /> : lang.toUpperCase()}
                            </Option>
                        );
                    })}
            </Select>
        );
    }
};
