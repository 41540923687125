import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    folderName: null
};

const selectedFolder = createSlice({
    name: 'selectedFolder',
    initialState,
    reducers: {
        selectFolder(state, action) {
            const { folderName} = action.payload;
            state.folderName = folderName;
            
            
        }
    }
});

export const { selectFolder } = selectedFolder.actions;

export default selectedFolder.reducer;