import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    projectId: undefined,
    projectName: '',
    themeShortName: '',
};

const selectedProject = createSlice({
    name: 'selectedProject',
    initialState,
    reducers: {
        selectProject(state, action) {
            const { projectId, projectName, themeShortName } = action.payload;
            state.projectId = projectId;
            state.projectName = projectName;
            state.themeShortName = themeShortName;
        },
    },
});

export const { selectProject } = selectedProject.actions;

export default selectedProject.reducer;
