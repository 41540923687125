import React, { Component } from 'react';
import { Spin } from 'antd';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Session } from '../utils/Session';
import PrimeIndex from './prime/PrimeIndex';
import './app.css';
import ReactGA from 'react-ga';
import config from '../config.json';
import Index from './search-results/Index';
import '../themes/nytourist/assets/css/global.css';
import '@nybble/ny-tourist-components/build/styles/styles.scss';

if (config.ga) {
    ReactGA.initialize(config['ga-code']);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await Session.init();
        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="spin-container">
                    <Spin size="large" />
                </div>
            );
        } else {
            return (
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={PrimeIndex} />
                        <Route key="/search-results" path="/search-results/:typeId?/:locationId?" component={Index} />
                    </Switch>
                </HashRouter>
            );
        }
    }
}

export default App;
