const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/' : '/api/';

export const DEFAULTS = {
    lang: 'hr',
};

export const EU_DEFAULTS = {
    lang: 'en',
};

export const CONSTANTS_REQ = {
    INIT: URL_PREFIX + 'init',
    REFRESHTOKEN: URL_PREFIX + 'refresh_token',
    LANG: {
        LIST: URL_PREFIX + 'lang/list',
        EDIT: URL_PREFIX + 'lang',
        FORPROJECT: URL_PREFIX + 'lang/forProject',
        VALIDATEABB: URL_PREFIX + 'lang/validateAbb',
        FORAPP: URL_PREFIX + 'lang/forApp',
    },
    DISK_FILE: {
        LIST: URL_PREFIX + 'diskFile/list',
        SEARCH: URL_PREFIX + 'diskFile/search',
        EDIT: URL_PREFIX + 'diskFile',
        UPLOAD: URL_PREFIX + 'diskFile/upload',
        DOWNLOAD: URL_PREFIX + 'diskFile/download',
        PDF: URL_PREFIX + 'diskFile/pdf',
        DELETE: URL_PREFIX + 'diskFile/delete/',
    },
    SETTINGS: {
        EDIT: URL_PREFIX + 'settings',
        SAVE_SETTINGS: URL_PREFIX + 'settings/saveSettings',
    },
    CLIENT: {
        PROJECT_LIST: URL_PREFIX + 'project/client/list',
        SETTINGS_EDIT: URL_PREFIX + 'settings/client',
        PAGETYPE_LIST: URL_PREFIX + 'pageType/client/list',
        WEBPAGE_LIST: URL_PREFIX + 'webPage/client/list',
        WEBPAGECOMPONENT_LIST: URL_PREFIX + 'pageComponent/client/list',
        DISK_FILE_DOWNLOAD: URL_PREFIX + 'diskFile/client/download',
        DISK_FILE_PDF: URL_PREFIX + 'diskFile/client/pdf',
        MAP_PIN_LIST: URL_PREFIX + 'mapPin/client/list',
        LOCATION_LIST: URL_PREFIX + 'location/client/list',
        LOCATION_EDIT: URL_PREFIX + 'location/client',
        FACILITY_LIST: URL_PREFIX + 'facility/client/listByPage',
        SEARCH_TYPE_EDIT: URL_PREFIX + 'searchContentType/client',
        SEARCH_CONTENT_TYPE: URL_PREFIX + 'searchContentType/client',
        SEARCH_CONTENT_TYPE_FILTER: URL_PREFIX + 'searchContentType/client/filter',
        KEY_FACILITY_EDIT: URL_PREFIX + 'facility/client',
        FACILITY_GROUPS: URL_PREFIX + 'facility/client/group',
        FACILITY_TREE: URL_PREFIX + 'facility/client/facilityTree',
        VIDEO_RANGE_STREAM: URL_PREFIX + 'diskFile/videoRangeStream',
    },
    PRIME: {
        PROJECT_LIST: URL_PREFIX + 'prime/projectList',
    },
    SEARCH: {
        LIST: URL_PREFIX + 'search/list',
    },
    STATIC: URL_PREFIX + 'media/',
    STATIC_MEDIA: URL_PREFIX,
};

export const CONSTANTS_FILE_TYPE = {
    IMAGE: 0,
    VIDEO: 1,
    OTHER: 2,
};

export const CONSTANTS_STORAGE = {
    DEFAULTS: 'hr.nybble.nytourist.defaults',
    SESSION: 'hr.nybble.nytourist.session',
};
