import { Search } from '@nybble/ny-tourist-components';
import { Button, Form, Icon, Select, Spin, Tooltip, Tree, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getTranslated } from '../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPost, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session, getTranslation } from '../../utils/Session';
import { getSelectedLanguage } from '../../utils/Utils';
import useWindowDimensions from '../../utils/Utils';
import { Map, Marker, TileLayer, ZoomControl, Popup } from 'react-leaflet';
import L, { map } from 'leaflet';
import '../prime/MapWidget.css';
import './SearchResultsIndex.css';

const { Option } = Select;
const { TreeNode } = Tree;

const ButtonGroup = Button.Group;

const SearchResultsIndex = () => {
    const history = useHistory();
    const { typeId, locationId } = useParams();

    const selLang = getSelectedLanguage();

    const [searchResults, setSearchResults] = useState([]);
    const [numberOfElements, setNumberOfElements] = useState('0');
    const [loading, setLoading] = useState(true);
    const [gridView, setGridView] = useState('active');
    const [mapView, setMapView] = useState(null);
    const [categoriesFilter, setCategoriesFilter] = useState([]);
    const [keyFacilitiesFilter, setKeyFacilitiesFilter] = useState([]);
    const [keyFacilitiesParent, setKeyFacilitiesParent] = useState([]);
    const [facilitiesGroupsFilter, setFacilitiesGroupsFilter] = useState([]);
    const [lat, setLat] = useState(44.5257);
    const [lng, setLng] = useState(16.3366);
    const [zoom, setZoom] = useState(7);
    const [mapRef, setMapRef] = useState(null);
    const [checkedStars, setCheckedStars] = useState([]);
    const [checkedKeyFacilities, setCheckedKeyFacilities] = useState([]);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedFacilitiesGroup, sethCheckedFacilitiesGroup] = useState([]);
    const [mapPins, setMapPins] = useState([]);
    const { height, width } = useWindowDimensions();
    const [forMap, setForMap] = useState(false);
    const [pagination, setPagination] = useState({
        max: 12,
        offset: 0,
    });
    const [checkedOrCategories, setCheckedOrCategories] = useState([]);
    const [checkedAndCategories, setCheckedAndCategories] = useState([]);

    let clicked = 0;

    const blueIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    var homeIcon = L.divIcon({
        className: 'marker-pin-icon',
        html: '<div class="marker-pin marker-pin-bounce"></div><i aria-label="icon: home" class="anticon anticon-home marker-pin-bounce-icon"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path></svg></i>',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    useEffect(() => {
        if (width < 768) {
            setZoom(6);
        } else {
            setZoom(7);
        }
    }, [width]);

    useEffect(() => {
        setLoading(true);
        getSearchResults(false);
        getCategoriesFilter();
        getKeyFacilities();
        getFacilitiesGroup();
    }, []);

    useEffect(() => {
        setLoading(true);
        getSearchResults(false);
        getCategoriesFilter();
        getKeyFacilities();
        getFacilitiesGroup();
    }, [typeId]);

    useEffect(() => {
        setLoading(true);
        getSearchResults(false);
        getCategoriesFilter();
        getKeyFacilities();
        getFacilitiesGroup();
    }, [locationId]);

    useEffect(() => {
        getSearchResults(true);
    }, [checkedStars]);

    useEffect(() => {
        getSearchResults(true);
    }, [checkedKeyFacilities]);

    useEffect(() => {
        getSearchResults(true);
    }, [checkedCategories]);

    useEffect(() => {
        getSearchResults(true);
    }, [checkedFacilitiesGroup]);
    useEffect(() => {
        setLoading(true);
        getSearchResults(true, pagination);
    }, [pagination]);

    useEffect(() => {
        setLoading(true);
        getSearchResults(true);
    }, [forMap]);

    const getSearchResults = (
        isActive,
        pagination = {
            max: 12,
            offset: 0,
        }
    ) => {
        if (isActive) {
            let params = {
                searchTypesAnd: typeId != null && typeId != 'undefined' ? [typeId, ...checkedAndCategories] : [],
                searchTypesOr: typeId != null && typeId != 'undefined' ? [...checkedOrCategories] : [],
                locations: locationId != null && locationId != 'undefined' ? [locationId] : [],
                projects: [],
                facilities: [...checkedKeyFacilities, ...checkedFacilitiesGroup],
                stars: checkedStars,
                forMap: forMap,
                searchFor: 'web',
            };
            requestPost(CONSTANTS_REQ.SEARCH.LIST, null, { ...pagination, ...params }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    const responseData = resp.data;
                    setSearchResults(resp.data.content);
                    setNumberOfElements(responseData.totalSize);
                    setLoading(false);
                    let tmp = [];
                    if (resp.data.numberOfElements > 0) {
                        resp.data.content.forEach(function (item) {
                            if (item.latitude != null && item.longitude != null) {
                                tmp.push(item);
                            }
                        });
                    }
                    setMapPins(tmp);
                } else {
                }
            });
        } else {
            let params = {
                searchTypesAnd: typeId != null && typeId != 'undefined' ? [typeId] : [],
                searchTypesOr: [],
                locations: locationId != null && locationId != 'undefined' ? [locationId] : [],
                projects: [],
                facilities: [],
                stars: [],
                forMap: forMap,
                searchFor: 'web',
            };
            requestPost(CONSTANTS_REQ.SEARCH.LIST, null, { ...pagination, ...params }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    console.log(resp.data.content);
                    const responseData = resp.data;
                    setSearchResults(resp.data.content);
                    setNumberOfElements(responseData.totalSize);
                    setLoading(false);
                    let tmp = [];
                    if (resp.data.numberOfElements > 0) {
                        resp.data.content.forEach(function (item) {
                            if (item.latitude != null && item.longitude != null) {
                                tmp.push(item);
                            }
                        });
                    }
                    setMapPins(tmp);
                } else {
                }
            });
        }
    };

    const getCategoriesFilter = () => {
        requestGet(CONSTANTS_REQ.CLIENT.SEARCH_CONTENT_TYPE_FILTER + '/' + typeId).then((resp) => {
            if (resp.status === RESPONSE.OK) {
                setCategoriesFilter(resp.data);
            }
        });
    };

    const getKeyFacilities = () => {
        requestGet(CONSTANTS_REQ.CLIENT.KEY_FACILITY_EDIT + '/0').then((resp) => {
            if (resp.status === RESPONSE.OK) {
                setKeyFacilitiesFilter(resp.data);
                let has_parent = [];
                resp.data.forEach(function (key) {
                    if ('id' in key.parent) {
                        has_parent.push(key.id);
                    }
                });
                setKeyFacilitiesParent(has_parent);
            }
        });
    };

    const getFacilitiesGroup = () => {
        requestGet(CONSTANTS_REQ.CLIENT.FACILITY_TREE + '?searchTypeId=' + typeId).then((resp) => {
            if (resp.status === RESPONSE.OK) {
                setFacilitiesGroupsFilter(resp.data);
            }
        });
    };

    const GenerateStarts = ({ searchStarsNumber }) => {
        let stars = [];
        for (var i = 0; i < searchStarsNumber; i++) {
            stars.push(<Icon type="star" />);
        }

        return stars;
    };

    const projectClick = (subdomain, domain, id, alias) => {
        if (domain != null && subdomain != null && subdomain != '@') {
            window.open(
                'https://' +
                    subdomain +
                    (domain.startsWith('.') ? '' : '.') +
                    domain +
                    '/' +
                    selLang +
                    '/' +
                    (alias != null ? alias : id),
                '_blank'
            );
        } else if (domain != null && subdomain != null && subdomain == '@') {
            window.open(
                'https://' +
                    (domain.startsWith('.') ? domain.substring(1) : domain) +
                    '/' +
                    selLang +
                    '/' +
                    (alias != null ? alias : id),
                '_blank'
            );
        }
    };

    const list = (i, direction) => {
        i = getNextMapPinIndex(i, direction);
        clicked = i;
        mapRef.leafletElement.flyTo([mapPins[i].latitude, mapPins[i].longitude], 10);
        markerRefs[mapPins[i].id].leafletElement.openPopup();
    };

    const getNextMapPinIndex = (index, direction) => {
        let fIndex = -1;
        do {
            if (typeof mapPins[index] === 'undefined') {
                if (index === -1) {
                    index = mapPins.length - 1;
                } else {
                    index = 0;
                }
            }
            if (mapPins[index].latitude && mapPins[index].longitude) {
                fIndex = index;
            } else if (direction === 0) {
                index -= 1;
            } else {
                index += 1;
            }
        } while (fIndex === -1);
        return fIndex;
    };

    let markerRefs = [];

    const MapPin = (item) => {
        const clickZoom = (e) => {
            let latLng = e.target.getLatLng();
            mapRef.leafletElement.flyTo(latLng, 10);
            let index = 0;
            for (let pin of mapPins) {
                if (pin.latitude == latLng.lat && pin.longitude == latLng.lng) {
                    clicked = index;
                    break;
                }
                index += 1;
            }
        };

        return (
            item.latitude &&
            item.longitude && (
                <Marker
                    ref={(ref) => {
                        markerRefs[item.id] = ref;
                    }}
                    key={item.id}
                    icon={homeIcon}
                    position={[item.latitude, item.longitude]}
                    onclick={clickZoom}
                >
                    <Popup>
                        {item.domain != null && item.subdomain != null && (
                            <div className="popup-content">
                                <a
                                    onClick={() => {
                                        projectClick(item.subdomain, item.domain, item.id);
                                    }}
                                >
                                    {item.searchImage != null && (
                                        <img
                                            src={CONSTANTS_REQ.STATIC + item.projectId + encodeURI(item.searchImage)}
                                            width="100%"
                                            height="150"
                                            className="popup-img"
                                        />
                                    )}
                                    {item.searchImage == null && (
                                        <div className="popup-svg">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                width="100%"
                                                height="100%"
                                                viewBox="0 0 512 512"
                                                enable-background="new 0 0 512 512"
                                                className="popup-img"
                                            >
                                                <g>
                                                    <path
                                                        fill="#AAAAAA"
                                                        d="M0,48v416h512V48H0z M480,432H32V80h448V432z M352,160c0,26.51,21.49,48,48,48s48-21.49,48-48   s-21.49-48-48-48S352,133.49,352,160z M448,400H64l96-256l128,160l64-48L448,400z"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    )}
                                </a>
                                <a
                                    onClick={() => {
                                        projectClick(item.subdomain, item.domain, item.id, item.alias);
                                    }}
                                >
                                    <div class="popup-title">
                                        <span>{getTranslated(item.name, selLang)}</span>
                                    </div>
                                </a>
                                {item.searchDescription != null && (
                                    <div class="popup-text">
                                        <span>{getTranslated(item.searchDescription, selLang)}</span>
                                    </div>
                                )}
                            </div>
                        )}
                        {(item.domain == null || item.subdomain == null) && (
                            <div className="popup-content">
                                {item.searchImage != null && (
                                    <img
                                        src={CONSTANTS_REQ.STATIC + item.projectId + encodeURI(item.searchImage)}
                                        width="100%"
                                        height="150"
                                        className="popup-img"
                                    />
                                )}
                                {item.searchImage == null && (
                                    <div className="popup-svg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 512 512"
                                            enable-background="new 0 0 512 512"
                                            className="popup-img"
                                        >
                                            <g>
                                                <path
                                                    fill="#AAAAAA"
                                                    d="M0,48v416h512V48H0z M480,432H32V80h448V432z M352,160c0,26.51,21.49,48,48,48s48-21.49,48-48   s-21.49-48-48-48S352,133.49,352,160z M448,400H64l96-256l128,160l64-48L448,400z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                )}
                                <div class="popup-title">{item.name}</div>
                            </div>
                        )}
                    </Popup>
                </Marker>
            )
        );
    };

    const ResultCard = ({
        id,
        searchStarsNumber,
        searchDescription,
        searchImage,
        facilities,
        price,
        searchLink,
        name,
        projectId,
        subdomain,
        domain,
        alias,
    }) => {
        return (
            <div className="results__product__card col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <a
                    onClick={() => {
                        projectClick(subdomain, domain, id, alias);
                    }}
                >
                    <div className="results__product__card__wrap">
                        <div className="results__product__card__image__wrap">
                            {searchImage != null && (
                                <img src={CONSTANTS_REQ.STATIC + projectId + encodeURI(searchImage)} />
                            )}
                            {searchImage == null && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 512 512"
                                    enable-background="new 0 0 512 512"
                                >
                                    <g>
                                        <path
                                            fill="#AAAAAA"
                                            d="M0,48v416h512V48H0z M480,432H32V80h448V432z M352,160c0,26.51,21.49,48,48,48s48-21.49,48-48   s-21.49-48-48-48S352,133.49,352,160z M448,400H64l96-256l128,160l64-48L448,400z"
                                        />
                                    </g>
                                </svg>
                            )}
                        </div>
                        <div className="results__product__card__content">
                            <div className="results__product__card__meta">
                                <h2 className="results__product__card__title">{getTranslated(name, selLang)}</h2>
                                <div className="results__product__card__star-rating">
                                    {searchStarsNumber != null && (
                                        <GenerateStarts searchStarsNumber={searchStarsNumber} />
                                    )}
                                </div>
                            </div>
                            <div className="results__product__card__description">
                                <p>{getTranslated(searchDescription, selLang)}</p>
                            </div>
                        </div>
                        <div className="results__product__card__facilities">
                            {facilities != null &&
                                facilities.map((item) => (
                                    <Tooltip title={getTranslated(item.name, selLang)}>
                                        <img src={CONSTANTS_REQ.STATIC_MEDIA + encodeURI(item.icon)} />
                                    </Tooltip>
                                ))}
                        </div>
                        <div className="results__product__card__cta">
                            <div className="row" style={{ width: '100%', marginLeft: '0px' }}>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <React.Fragment>
                                        <span className="results__product__card__cta__label">
                                            {getTranslation('searchResults.label.priceFrom', selLang)}
                                        </span>
                                        {price != null && (
                                            <span className="results__product__card__price">
                                                {getTranslated(price, selLang)}
                                            </span>
                                        )}
                                    </React.Fragment>
                                </div>
                                <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                    style={{ borderLeft: '1px solid #f2f2f2' }}
                                >
                                    <React.Fragment>
                                        <span className="results__product__card__cta__label">
                                            {getTranslation('searchResults.label.visit', selLang)}
                                        </span>
                                        {searchLink != null && (
                                            <a href={searchLink} className="results__product__card__website">
                                                {getTranslation('searchResults.label.website', selLang)} {'  '}{' '}
                                                <Icon type="link" />
                                            </a>
                                        )}
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    };

    const onGridView = (e) => {
        e.preventDefault();
        setGridView('active');
        setMapView(null);
        setForMap(false);
    };

    const onMapView = (e) => {
        e.preventDefault();
        setMapView('active');
        setGridView(null);
        setForMap(true);
    };

    const onCheckStars = (checkedKeys, info) => {
        setCheckedStars(checkedKeys);
    };
    const onChangePagination = (page, pageSize) => {
        setPagination({
            max: pageSize,
            offset: page - 1,
        });
    };

    const onClickKeyFacilities = (e, key) => {
        e.preventDefault();
        let checkedKeys = [...checkedKeyFacilities];
        if (checkedKeys.includes(key)) {
            const index = checkedKeys.indexOf(key);
            if (index > -1) {
                checkedKeys.splice(index, 1);
            }
            setCheckedKeyFacilities(checkedKeys);
        } else {
            checkedKeys.push(key);
            setCheckedKeyFacilities(checkedKeys);
        }

        setFacilitiesGroup(key);
    };

    function setFacilitiesGroup(key) {
        if (keyFacilitiesParent.includes(key)) {
            let checkedGroupKeys = [...checkedFacilitiesGroup];

            if (checkedGroupKeys.includes(key)) {
                const index = checkedGroupKeys.indexOf(key);
                if (index > -1) {
                    checkedGroupKeys.splice(index, 1);
                }
            } else {
                checkedGroupKeys.push(key);
            }

            sethCheckedFacilitiesGroup(checkedGroupKeys);
        }
    }

    const onCheckCategoriesFilter = (checkedKeys, info) => {
        var or_keys = [];
        var and_keys = [];
        checkedKeys.forEach(function (key) {
            if (key.includes('or')) {
                or_keys.push(key.split('-')[0]);
            } else {
                and_keys.push(key.split('-')[0]);
            }
        });
        setCheckedCategories(checkedKeys);
        setCheckedAndCategories(and_keys);
        setCheckedOrCategories(or_keys);
    };

    const onCheckFacilitiesGroup = (checkedGroupKeys, info) => {
        let oldCheckedGroupKeys = [...checkedFacilitiesGroup];
        sethCheckedFacilitiesGroup(checkedGroupKeys);
        setKeyFacilities(checkedGroupKeys, oldCheckedGroupKeys);
    };

    function setKeyFacilities(checkedGroupKeys, oldCheckedGroupKeys) {
        let checkedKeys = [...checkedKeyFacilities];

        oldCheckedGroupKeys.forEach(function (key) {
            key = parseInt(key);
            if (keyFacilitiesParent.includes(key) && checkedKeys.includes(key)) {
                const index = checkedKeys.indexOf(key);
                if (index > -1) {
                    checkedKeys.splice(index, 1);
                }
            }
        });

        checkedGroupKeys.forEach(function (key) {
            key = parseInt(key);
            if (keyFacilitiesParent.includes(key) && !checkedKeys.includes(key)) {
                checkedKeys.push(key);
            }
        });

        setCheckedKeyFacilities(checkedKeys);
    }

    const clearFilter = () => {
        setCheckedStars([]);
        setCheckedKeyFacilities([]);
        setCheckedCategories([]);
        setCheckedAndCategories([]);
        setCheckedOrCategories([]);
        sethCheckedFacilitiesGroup([]);
        getSearchResults(false);
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                    <Search
                        Select={Select}
                        Option={Option}
                        Button={Button}
                        getSelectedClientLanguage={getSelectedLanguage}
                        getTranslated={getTranslated}
                        history={history}
                        requestGet={requestGet}
                        RESPONSE={RESPONSE}
                        geti18nText={geti18nText}
                        CONSTANTS_REQ={CONSTANTS_REQ}
                        projectType={'prime'}
                        defaultSelectTypeValue={typeId}
                        defaultSelectLocationValue={locationId}
                    />
                </div>
            </div>
            {!loading ? (
                <div className="searchresult__container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12" style={{ marginBottom: '20px' }}>
                            <div className="filter__card col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                <div className="filter__card-heading">
                                    <div className="filter__card__heading-title">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-8 col-xs-6">
                                                <Icon type="filter" />
                                                {'  '} {getTranslation('searchResults.label.filter', selLang)}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-4 col-xs-6">
                                                {' '}
                                                <Button
                                                    size="small"
                                                    style={{ float: 'right' }}
                                                    onClick={(e) => clearFilter()}
                                                >
                                                    {getTranslation('app.default.btn.clear.filter', selLang)}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="search__panel">
                                    <div className="results__filters__panel">
                                        <h4 className="results__filters__title">
                                            {getTranslation('searchResults.label.officialRating', selLang)}
                                        </h4>

                                        <Tree
                                            onCheck={onCheckStars}
                                            checkedKeys={checkedStars}
                                            checkable
                                            style={{ fontSize: '22px', marginLeft: '-25px' }}
                                        >
                                            <TreeNode title={'★ ★ ★ ★ ★'} key={5} />
                                            <TreeNode title={'★ ★ ★ ★ ✰'} key={4} />
                                            <TreeNode title={'★ ★ ★ ✰ ✰'} key={3} />
                                            <TreeNode title={'★ ★ ✰ ✰ ✰'} key={2} />
                                            <TreeNode title={'★ ✰ ✰ ✰ ✰'} key={1} />
                                        </Tree>
                                    </div>

                                    <div className="results__filters__panel">
                                        <h4 className="results__filters__title">
                                            {getTranslation('searchResults.label.categories', selLang)}
                                        </h4>
                                        <Tree
                                            checkable
                                            checkedKeys={checkedCategories}
                                            onCheck={onCheckCategoriesFilter}
                                        >
                                            {categoriesFilter != null &&
                                                categoriesFilter.map((item) => (
                                                    <TreeNode
                                                        checkable={false}
                                                        title={getTranslated(item.name, selLang)}
                                                        key={item.id}
                                                        ref={item}
                                                    >
                                                        {item.children != null &&
                                                            item.children.map((value) => (
                                                                <TreeNode
                                                                    className="ny-tree"
                                                                    title={getTranslated(value.name, selLang)}
                                                                    key={value.id + '-' + value.searchAs}
                                                                />
                                                            ))}
                                                    </TreeNode>
                                                ))}
                                        </Tree>
                                    </div>

                                    <div className="results__filters__panel">
                                        <h4 className="results__filters__title">
                                            {getTranslation('searchResults.label.keyFacilities', selLang)}
                                        </h4>
                                        <div className="results__filters__top-facilities__wrapper">
                                            {keyFacilitiesFilter.map((item) => (
                                                <Tooltip title={getTranslated(item.name, selLang)}>
                                                    {checkedKeyFacilities && (
                                                        <span
                                                            className={
                                                                checkedKeyFacilities.includes(item.id)
                                                                    ? 'checkbox-fancytoggle-active'
                                                                    : 'checkbox-fancytoggle'
                                                            }
                                                            onClick={(e) => onClickKeyFacilities(e, item.id)}
                                                        >
                                                            <img
                                                                src={CONSTANTS_REQ.STATIC_MEDIA + encodeURI(item.icon)}
                                                            />
                                                        </span>
                                                    )}
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="results__filters__panel">
                                        <h4 className="results__filters__title">
                                            {getTranslation('searchResults.label.facilities', selLang)}
                                        </h4>
                                        <Tree
                                            checkable
                                            checkedKeys={checkedFacilitiesGroup}
                                            onCheck={onCheckFacilitiesGroup}
                                        >
                                            {facilitiesGroupsFilter != null &&
                                                facilitiesGroupsFilter.map((item) => (
                                                    <TreeNode
                                                        checkable={false}
                                                        title={getTranslated(item.name, selLang)}
                                                        key={item.id}
                                                        ref={item}
                                                    >
                                                        {item.children != null &&
                                                            item.children.map((value) => (
                                                                <TreeNode
                                                                    className="ny-tree"
                                                                    title={getTranslated(value.name, selLang)}
                                                                    key={value.id}
                                                                />
                                                            ))}
                                                    </TreeNode>
                                                ))}
                                        </Tree>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <div className="row">
                                <div className="col-lg-7 col-md-5 col-sm-12 col-xs-12">
                                    <h1 className="results__title" style={{ textTransform: 'uppercase' }}>
                                        <span style={{ color: '#767575' }}>
                                            {numberOfElements != null && numberOfElements}
                                        </span>{' '}
                                        {getTranslation('searchResults.label.searchResults', selLang)}
                                    </h1>
                                </div>

                                <div className="col-lg-5 col-md-7 col-sm-12 col-xs-12">
                                    <div className="results__options">
                                        {/* <div className="results__sort">
                                        <label className="results__label select-wrap">
                                            <span className="results__sort__label">Sort by:</span>
                                            <select id="sort-results" className="results__sort-dropdown form-control">
                                                <option value="">Unsorted</option>
                                                <option value="gradingDesc">Official Rating: High-Low</option>
                                                <option value="guideprice">Price: Low-High</option>
                                                <option value="guidepriceDesc">Price: High-Low</option>
                                            </select>
                                        </label>
                                    </div> */}
                                        <div className="results__grid-view">
                                            <span className="results__grid__switcher">
                                                {getTranslation('searchResults.label.switchLayout', selLang)}
                                            </span>
                                            <div
                                                className={`results__grid-view__btn ${gridView}`}
                                                tabIndex={0}
                                                onClick={(e) => onGridView(e)}
                                            >
                                                <div className="results__grid-view__gridText">
                                                    {getTranslation('searchResults.label.gridView', selLang)}
                                                </div>
                                            </div>
                                            <button
                                                className={`results__grid-view__btn layoutMap ${mapView}`}
                                                tabIndex={1}
                                                onClick={(e) => onMapView(e)}
                                            >
                                                <i className="vsicons-pin"></i>
                                                <div className="">
                                                    {getTranslation('searchResults.label.mapView', selLang)}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {gridView === 'active' ? (
                                <div>
                                    <div className="row" style={{ marginTop: '25px' }}>
                                        {searchResults != null &&
                                            searchResults.map((item) => (
                                                <ResultCard
                                                    searchStarsNumber={
                                                        item.searchStarsNumber != null ? item.searchStarsNumber : 0
                                                    }
                                                    searchDescription={
                                                        item.searchDescription != null ? item.searchDescription : ''
                                                    }
                                                    searchImage={item.searchImage}
                                                    facilities={item.facilities}
                                                    price={item.price}
                                                    searchLink={item.searchLink}
                                                    name={item.name != null ? item.name : ''}
                                                    id={item.id}
                                                    projectId={item.projectId}
                                                    subdomain={item.subdomain}
                                                    domain={item.domain}
                                                    alias={item.alias}
                                                />
                                            ))}
                                    </div>
                                    <div className="result-pagination-prime">
                                        <Pagination
                                            defaultCurrent={pagination.offset + 1}
                                            total={numberOfElements}
                                            pageSize={pagination.max}
                                            onChange={onChangePagination}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="row" style={{ marginTop: '25px' }}>
                                    <Map
                                        ref={(ref) => {
                                            setMapRef(ref);
                                        }}
                                        center={[lat, lng]}
                                        zoom={zoom}
                                        style={{ height: '800px', width: '100%' }}
                                        doubleClickZoom={false}
                                        trackResize={false}
                                        zoomControl={false}
                                        touchZoom={true}
                                        keyboard={false}
                                        gestureHandling={true}
                                        attributionControl={false}
                                        gestureHandlingOptions={{
                                            text: {
                                                touch:
                                                    Session.getProjectInfo() &&
                                                    Session.getProjectInfo().mapZoomText &&
                                                    getTranslated(Session.getProjectInfo().mapZoomText, selLang)
                                                        ? getTranslated(Session.getProjectInfo().mapZoomText, selLang)
                                                        : 'Use two fingers to move the map',
                                                scroll: 'Use ctrl + scroll to zoom the map',
                                                scrollMac: 'Use \u2318 + scroll to zoom the map',
                                            },
                                        }}
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <ZoomControl position="topright" />
                                        {searchResults != null &&
                                            searchResults.map(
                                                (item) =>
                                                    item.latitude != null && item.longitude != null && MapPin(item)
                                            )}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '10px',
                                                bottom: '20px',
                                                zIndex: 400,
                                                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <ButtonGroup>
                                                <Tooltip
                                                    title={getTranslation('map.tooltip.prev', selLang)}
                                                    align="middle"
                                                >
                                                    <Button
                                                        onClick={() => list(clicked - 1, 0)}
                                                        style={{ width: '110px' }}
                                                        className="prime-title-font"
                                                    >
                                                        <Icon type="left" /> {getTranslation('map.prev', selLang)}
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip
                                                    title={getTranslation('map.tooltip.next', selLang)}
                                                    align="middle"
                                                >
                                                    <Button
                                                        onClick={() => list(clicked + 1)}
                                                        style={{ width: '110px' }}
                                                        className="prime-title-font"
                                                    >
                                                        {getTranslation('map.next', selLang)}
                                                        <Icon type="right" />
                                                    </Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </div>
                                    </Map>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="spin-container" style={{ marginTop: '100px' }}>
                    <Spin size="large" />
                </div>
            )}
        </React.Fragment>
    );
};

export default SearchResultsIndex;
