import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import { ConfigProvider } from 'antd';
import { getSelectedLocale } from './utils/Utils';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import config from './config.json';
import { Provider } from 'react-redux';
import store from './store';
import '@nybble/ny-tourist-components/build/styles/styles.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'video-react/dist/video-react.css';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={getSelectedLocale()}>
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

if (config.title != null) {
    document.title = config.title;
} else {
    document.title = 'Smart Tourist';
}
