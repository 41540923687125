import { Session } from './Session';

//Get method - HTTP Get
export const requestGet = (url, params = undefined, returnJson = true, returnBlob = false) => {
    return request(getUrl(url, params), FETCH_METHODS.GET, undefined, returnJson, returnBlob);
};

//Post method - HTTP Post
export const requestPost = (url, params = undefined, data = undefined, returnJson = true) => {
    return request(getUrl(url, params), FETCH_METHODS.POST, data, returnJson);
};

//Post method - HTTP Put
export const requestPut = (url, params = undefined, data = undefined, returnJson = true) => {
    return request(getUrl(url, params), FETCH_METHODS.PUT, data, returnJson);
};

//Post method - HTTP Post file
export const requestPostFile = (url, params = undefined, data = undefined, returnJson = true) => {
    return request(getUrl(url, params), FETCH_METHODS.POST, data, returnJson, false, true);
};

//Post method - HTTP Delete
export const requestDelete = (url, params = undefined, data = undefined, returnJson = true) => {
    return request(getUrl(url, params), FETCH_METHODS.DELETE, data, returnJson);
};

//Putting params into URL
export const generateUrl = (url, params = undefined) => {
    return getUrl(url, params);
};

//Supported response status
export const RESPONSE = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTORIZED: 401,
    NOTFOUND: 404,
    NOT_ACCEPTABLE: 406,
    INTERNAL_SERVER_ERROR: 500,
};

//Supported methods
const FETCH_METHODS = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

//Default headers
let FETCH_HEADERS = {
    'Content-Type': 'application/json',
};

//Default fetch object
const FETCH_OBJECT = (method, body, sendFile = false) => {
    let ret = {
        method: method,
    };
    if (sendFile) {
        ret.headers = {};
    } else {
        ret.headers = FETCH_HEADERS;
    }

    if (Session.getUserToken()) {
        ret.headers['Authorization'] = 'Bearer ' + Session.getUserToken();
    }
    if (body) {
        if (sendFile) {
            ret.body = body;
        } else {
            ret.body = JSON.stringify(body);
        }
    }
    return ret;
};

//check if token is expired, refresh token and call request
const request = async function (
    url,
    method,
    body = undefined,
    returnJson = true,
    returnBlob = false,
    sendFile = false,
    doNotRefreshPage = false
) {
    let retValue = { status: 0, data: {} };

    const tokenExpire = Session.getTokenExpiration();
    if (tokenExpire && tokenExpire < Date.now()) {
        //console.log('TOKEN EXPIRED');
        const success = await Session.requestRefreshToken();
        if (!success) {
            //console.log('TOKEN NOT REFRESHED');
            Session.logoutUser();
            window.location.reload();
            return retValue;
        } else {
            //console.log('TOKEN REFRESHED');
            return requestFinall(url, method, body, returnJson, returnBlob, sendFile, doNotRefreshPage);
        }
    } else {
        return requestFinall(url, method, body, returnJson, returnBlob, sendFile, doNotRefreshPage);
    }
};

//default request
const requestFinall = async function (
    url,
    method,
    body = undefined,
    returnJson = true,
    returnBlob = false,
    sendFile = false,
    doNotRefreshPage = false
) {
    let retValue = { status: 0, data: {} };
    try {
        let response = await fetch(url, FETCH_OBJECT(method, body, sendFile));
        if (response.status === RESPONSE.UNAUTORIZED) {
            //console.log('UNAUTORIZED');
            if (Session.getRefreshToken() !== undefined) {
                //console.log('REFRESH TOKEN EXISTS');
                const success = await Session.requestRefreshToken();
                if (success) {
                    return requestFinall(url, method, body, returnJson, returnBlob, sendFile, doNotRefreshPage);
                } else {
                    Session.logoutUser();
                    window.location.reload();
                    return retValue;
                }
            } else {
                //console.log('REFRESH TOKEN NOT EXISTS');
                Session.logoutUser();

                if (!doNotRefreshPage) {
                    window.location.reload();
                }
            }
        } else {
            retValue.status = response.status;
            try {
                if (returnJson) {
                    retValue.data = await response.json();
                } else if (returnBlob) {
                    let fileObject = { filename: '', file: undefined };
                    retValue.data = { filename: '', file: '' };
                    if (response.headers.get('Content-Disposition')) {
                        const responseHeader = response.headers.get('Content-Disposition');
                        if (responseHeader != null) {
                            fileObject.filename = responseHeader.split('filename=')[1];
                        }
                        if (fileObject.filename) {
                            fileObject.filename = fileObject.filename.replace(/\"/g, '');
                        }
                    }
                    fileObject.file = await response.blob();
                    retValue.data = fileObject;
                } else {
                    retValue.data = await response.text();
                }
                return retValue;
            } catch (e) {
                console.log('Error while waiting for response');
                console.log(e);
            }
        }
    } catch (error) {
        console.error('Error while Request method!!!');
        console.error(error);
        retValue.data = error;
    }
    return retValue;
};

//Function for putting params into URL
function getUrl(url, params) {
    let ret = url;
    let size = 0;
    if (params) {
        ret += '?';
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                if (size > 0) {
                    ret += '&';
                }
                ret += key + '=' + params[key];
                size++;
            }
        }
    }
    return ret;
}
