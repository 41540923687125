import { Session } from '../utils/Session';

export const getTranslated = (translations, language = Session.getProjectDefaultLanguage()) => {
    if (translations == undefined || translations.length == 0) {
        return '';
    }

    if (typeof translations == 'string') {
        translations = JSON.parse(translations);
    }

    if (translations[language]) {
        return translations[language];
    } else {
        return '';
    }
};
