import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    refreshLastNode: false,
    refreshProjectNode: false,
    refreshNodes: [],
};

const menuSclice = createSlice({
    name: 'menuRefresh',
    initialState,
    reducers: {
        refreshMenu(state, action) {
            const { refreshLastNode, refreshProjectNode, refreshNodes } = action.payload;
            state.refreshLastNode = refreshLastNode;
            state.refreshProjectNode = refreshProjectNode;
            state.refreshNodes = refreshNodes;
        },
    },
});

export const { refreshMenu } = menuSclice.actions;

export default menuSclice.reducer;
