import React, { useState, useEffect } from 'react';
import { Session, geti18nText, getTranslation } from '../../utils/Session';
import { getSelectedLanguage } from '../../utils/Utils';
import { Col, Button, Row, Tooltip, Icon } from 'antd';
import { getTranslated } from '../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../utils/Constants';
import 'leaflet/dist/leaflet.css';
import { applicationLogo } from '../../components/adminLayout/AdminMainMenu';
import { getSelectedClientLanguage } from '../../utils/Utils';
import { requestGet, RESPONSE } from '../../utils/RequestResolver';
import { Map, Marker, Popup, TileLayer, Polygon, ZoomControl } from 'react-leaflet';
import L, { map } from 'leaflet';
import './MapWidget.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import useWindowDimensions from '../../utils/Utils';

const blueIcon = new L.Icon({
    iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

var homeIcon = L.divIcon({
    className: 'marker-pin-icon',
    html:
        '<div class="marker-pin marker-pin-bounce"></div><i aria-label="icon: home" class="anticon anticon-home marker-pin-bounce-icon"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path></svg></i>',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const ButtonGroup = Button.Group;

const MapWidget = () => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    const selLang = getSelectedLanguage();
    const [logo, setLogo] = useState(undefined);
    const setLang = getSelectedClientLanguage();
    const [mapPins, setMapPins] = useState([]);
    const [clicked, setClicked] = useState(undefined);
    const [destination, setDestination] = useState(true);
    const { height, width } = useWindowDimensions();
    const [mapZoom, setMapZoom] = useState(7);
    const [mapHeight, setMapHeight] = useState();
    const [mapLongLat, setMapLongLat] = useState([44.5257, 16.3366]);
    let mapRef = null;

    useEffect(() => {
        setLogo(applicationLogo(logo));
        getMapPins();
    }, []);

    useEffect(() => {
        if (width <= 1024 && height < width) {
            setMapZoom(6);
        } else if (width < 768) {
            setMapZoom(6);
        } else {
            setMapZoom(7);
        }

        if (mapRef != null) {
            mapRef.leafletElement.invalidateSize();
        }
    }, [width]);

    useEffect(() => {
        if (height <= 568 && height < width) {
            setMapHeight('100vh');
        } else if (height <= 568) {
            setMapHeight('67vh');
        } else {
            setMapHeight('calc(100vh - 328px)');
        }

        if (mapRef != null) {
            mapRef.leafletElement.invalidateSize();
        }
    }, [height]);

    async function getMapPins() {
        const resp = await requestGet(CONSTANTS_REQ.PRIME.PROJECT_LIST, { max: 1000 });
        if (resp.status === RESPONSE.OK) {
            if (resp.data.content != null) {
                console.log(resp.data.content);
                setMapPins(resp.data.content);
            }
        }
    }

    const list = (i, direction) => {
        i = getNextMapPinIndex(i, direction);
        setClicked(i);
        mapRef.leafletElement.flyTo([mapPins[i].latitude, mapPins[i].longitude], 10);
        markerRefs[mapPins[i].id].leafletElement.openPopup();
    };

    const getNextMapPinIndex = (index, direction) => {
        let fIndex = -1;
        do {
            if (typeof mapPins[index] === 'undefined') {
                if (index === -1) {
                    index = mapPins.length - 1;
                } else {
                    index = 0;
                }
            }
            if (mapPins[index].latitude && mapPins[index].longitude) {
                fIndex = index;
            } else if (direction === 0) {
                index -= 1;
            } else {
                index += 1;
            }
        } while (fIndex === -1);
        return fIndex;
    };

    let markerRefs = [];

    const MapPin = (item) => {
        const popupContent = {
            height: 'auto',
            width: '200px',
            marginTop: '20px',
        };

        const popupHead = {
            fontWeight: 'bold',
            fontSize: '16px',
            marginTop: '10px',
            marginBottom: '10px',
        };

        const popupText = {
            fontSize: '15px',
            marginBottom: '5px',
        };

        const link = {
            height: '45px',
            backgroundColor: '#fafafa',
            borderTop: '1px solid #f2f2f2',
            marginTop: '10px',
            textAlign: 'center',
            padding: '10px',
        };

        const clickZoom = (e) => {
            let latLng = e.target.getLatLng();
            mapRef.leafletElement.flyTo(latLng, 10);
            let index = 0;
            for (let pin of mapPins) {
                if (pin.latitude == latLng.lat && pin.longitude == latLng.lng) {
                    setClicked(index);
                    break;
                }
                index += 1;
            }
        };

        return (
            item.latitude &&
            item.longitude && (
                <Marker
                    ref={(ref) => {
                        markerRefs[item.id] = ref;
                    }}
                    key={item.id}
                    icon={homeIcon}
                    position={[item.latitude, item.longitude]}
                    onclick={clickZoom}
                >
                    <Popup>
                        {
                            item.domain != null && item.subdomain != null && (
                                <div className="popup-content">
                                    <a
                                        onClick={() => {
                                            handlePolygonOrPinClick(item);
                                        }}
                                    >
                                        {item.image_path && (
                                            <img
                                                src={
                                                    CONSTANTS_REQ.DISK_FILE.DOWNLOAD +
                                                    `?projectId=${item.id}&relativePath=${item.image_path}`
                                                }
                                                width="100%"
                                                height="150"
                                                className="popup-img"
                                            />
                                        )}
                                        {item.image_path == null && (
                                            <div className="popup-svg">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    width="100%"
                                                    height="100%"
                                                    viewBox="0 0 512 512"
                                                    enable-background="new 0 0 512 512"
                                                    className="popup-img"
                                                >
                                                    <g>
                                                        <path
                                                            fill="#AAAAAA"
                                                            d="M0,48v416h512V48H0z M480,432H32V80h448V432z M352,160c0,26.51,21.49,48,48,48s48-21.49,48-48   s-21.49-48-48-48S352,133.49,352,160z M448,400H64l96-256l128,160l64-48L448,400z"
                                                        />
                                                    </g>
                                                </svg>
                                            </div>
                                        )}
                                    </a>
                                    <a
                                        onClick={() => {
                                            handlePolygonOrPinClick(item);
                                        }}
                                    >
                                        <div class="popup-title">
                                            <span>{item.name}</span>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                        {
                            (item.domain == null || item.subdomain == null) && (
                                <div className="popup-content">
                                    {item.image_path != null && (
                                        <img
                                            src={
                                                CONSTANTS_REQ.DISK_FILE.DOWNLOAD +
                                                `?projectId=${item.id}&relativePath=${item.image_path}`
                                            }
                                            width="100%"
                                            height="150"
                                        />
                                    )}
                                    {item.image_path == null && (
                                        <div className="popup-svg">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                width="100%"
                                                height="100%"
                                                viewBox="0 0 512 512"
                                                enable-background="new 0 0 512 512"
                                                className="popup-img"
                                            >
                                                <g>
                                                    <path
                                                        fill="#AAAAAA"
                                                        d="M0,48v416h512V48H0z M480,432H32V80h448V432z M352,160c0,26.51,21.49,48,48,48s48-21.49,48-48   s-21.49-48-48-48S352,133.49,352,160z M448,400H64l96-256l128,160l64-48L448,400z"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    )}
                                    <div class="popup-title">{item.name}</div>
                                </div>
                            )
                        }
                    </Popup>
                </Marker>
            )
        );
    };

    const Region = (item) => {
        return item.region != null ? (
            <Polygon
                positions={JSON.parse(item.region)}
                color="blue"
                onclick={() => {
                    handlePolygonOrPinClick(item);
                }}
            />
        ) : (
            <React.Fragment />
        );
    };

    const handlePolygonOrPinClick = (item) => {
        //window.open('https://kiosk.smart-tourist.hr/#/' + item.id, '_blank');
        if (item.domain != null && item.subdomain != null && item.subdomain != '@') {
            window.open('https://' + item.subdomain + (item.domain.startsWith('.') ? '' : '.') + item.domain, '_blank');
        } else if(item.domain != null && item.subdomain != null && item.subdomain == '@'){
            window.open('https://' + (item.domain.startsWith('.') ? item.domain.substring(1) : item.domain), '_blank');
        }
    };

    const getClassNameForBtn = (selected) => {
        return selected == true ? 'prime-buttons prime-buttons-selected' : 'prime-buttons prime-buttons-unselected';
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Button
                        className={getClassNameForBtn(destination)}
                        onClick={() => {
                            setDestination(true);
                            mapRef.leafletElement.flyTo(mapLongLat, mapZoom);
                        }}
                    >
                        {getTranslation('prime.map.location', selLang)}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        className={getClassNameForBtn(!destination)}
                        onClick={() => {
                            setDestination(false);
                            mapRef.leafletElement.flyTo(mapLongLat, mapZoom);
                        }}
                    >
                        {getTranslation('prime.map.region', selLang)}
                    </Button>
                </Col>
            </Row>
            <Col style={{ border: '1px solid' }}>
                {mapHeight != null && (
                    <Map
                        ref={(ref) => {
                            mapRef = ref;
                        }}
                        center={mapLongLat}
                        zoom={mapZoom}
                        style={{ height: mapHeight, width: '100%' }}
                        doubleClickZoom={false}
                        trackResize={false}
                        zoomControl={false}
                        touchZoom={true}
                        keyboard={false}
                        gestureHandling={true}
                        attributionControl={false}
                        gestureHandlingOptions={{
                            text: {
                                touch:
                                    Session.getProjectInfo() &&
                                    Session.getProjectInfo().mapZoomText &&
                                    getTranslated(Session.getProjectInfo().mapZoomText, setLang)
                                        ? getTranslated(Session.getProjectInfo().mapZoomText, setLang)
                                        : 'Use two fingers to move the map',
                                scroll: 'Use ctrl + scroll to zoom the map',
                                scrollMac: 'Use \u2318 + scroll to zoom the map',
                            },
                        }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ZoomControl position="topright" />
                        {destination && mapPins != null && mapPins.map((item) => MapPin(item))}
                        {!destination && mapPins != null && mapPins.map((item) => Region(item))}
                    </Map>
                )}
            </Col>
            <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '20px',
                    zIndex: 400,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                    alignItems: 'center',
                }}
            >
                {destination && (
                    <ButtonGroup>
                        <Tooltip title={getTranslation('map.tooltip.prev', selLang)} align="middle">
                            <Button
                                onClick={() => list(clicked - 1, 0)}
                                style={{ width: '110px' }}
                                className="prime-title-font"
                            >
                                <Icon type="left" /> {getTranslation('map.prev', selLang)}
                            </Button>
                        </Tooltip>
                        <Tooltip title={getTranslation('map.tooltip.next', selLang)} align="middle">
                            <Button
                                onClick={() => list(clicked + 1)}
                                style={{ width: '110px' }}
                                className="prime-title-font"
                            >
                                {getTranslation('map.next', selLang)}
                                <Icon type="right" />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                )}
            </div>
        </div>
    );
};

export default MapWidget;
