import { combineReducers } from '@reduxjs/toolkit';
import projectSlicesReducer from './slices/projectSlices';
import menuSlicesReducer from './slices/menuSlice';
import fileUploadSlicesReducer from './slices/fileUploadSlices';
import webPageSlicesReducer from './slices/webPageSlice';

const rootReducer = combineReducers({
    projectSlices: projectSlicesReducer,
    menuSlices: menuSlicesReducer,
    fileUploadSlices: fileUploadSlicesReducer,
    webPageSlices: webPageSlicesReducer,
});

export default rootReducer;
