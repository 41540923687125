import { withRouter } from 'react-router-dom';
import logoSmTourBlack from '../../assets/images/sm_logo_black.png';
import logoSmTourColor from '../../assets/images/sm_logo_color.png';
import config from '../../config.json';

export const AdminMainMenu = withRouter(() => {});

export const applicationLogo = (logo) => {
    if (logo === undefined) {
        if (config.logo) {
            switch (config.logo) {
                case 'none':
                    return;
                case 'sm_tour_black':
                    return logoSmTourBlack;
                default:
                    return logoSmTourColor;
            }
        } else {
            return logoSmTourColor;
        }
    }
};
