import React, { useState, useEffect } from 'react';
import { geti18nText } from '../../utils/Session';
import { getSelectedClientLanguage } from '../../utils/Utils';
import { Row, Col, Form, Select, Button } from 'antd';
import { getTranslated } from '../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { applicationLogo } from '../../components/adminLayout/AdminMainMenu';
import { useHistory } from 'react-router-dom';
import { requestGet, RESPONSE } from '../../utils/RequestResolver';
import '../../themes/nytourist/assets/css/flexboxgrid.css';
import SearchResultsIndex from './SearchResultsIndex';
import '@nybble/ny-tourist-components/build/styles/styles.scss';
import { Menu, Icon } from 'antd';

const Index = () => {
    return (
        <Row>
            <Row>
                <Col span={24}>
                    <Menu
                        mode="horizontal"
                        align="left"
                        className="search-results-menu"
                        style={{ padding: '5px 1% 0px' }}
                    >
                        <Menu.Item key="back" style={{ zIndex: '1000' }}>
                            <a href="/" className="prime-font">
                                <Icon type="arrow-left" />
                                {geti18nText('searchResults.back')}
                            </a>
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SearchResultsIndex />
                </Col>
            </Row>
        </Row>
    );
};

export default Index;
